<template>
  <div class="roles">
    <div class="box box-default">
      <div class="box-header">
        <div class="flex flex-col-sm gap-8 align-items-center justify-between">
          <h3 class="box-title">Balance Sheet</h3>
          <div class="flex flex-col-sm gap-8 align-items-center">
            <span v-if="refreshLoader" class="text-danger text-bold flex align-items-center"
              v-html="refreshLoader"></span>
            <span v-if="loader" class="text-danger text-bold flex align-items-center" v-html="loader"></span>
            <button v-if="altCoinID" class="btn btn-primary btn-sm btn-flat mr-8" @click="altCoinID = null">
              Reset
            </button>
            <button v-if="isOnline === '1'" class="btn btn-primary btn-sm btn-flat" @click="reload()">Reload</button>
            <button v-if="isOnline === '1' && selectedCount" class="btn btn-info btn-sm btn-flat"
              @click="refreshSelectedBalance()">
              Refresh Balance
            </button>
            <button v-if="isOnline === '1' && selectedCount" class="btn btn-info btn-sm btn-flat"
              @click="refreshSelectedBalance(true)">
              Refresh Balance & Reload
            </button>
            <button v-if="isOnline === '1'" class="btn btn-primary btn-sm btn-flat" @click="refreshBalance()">
              Refresh Balance
            </button>
            <button v-if="isOnline === '1'" class="btn btn-primary btn-sm btn-flat" @click="refreshBalanceAndReload()">
              Refresh Balance & Reload
            </button>
            <button v-if="selectedCount" class="btn btn-info btn-sm btn-flat" @click="consolidateSelected(true)">
              Consolidate
            </button>
            <button class="btn btn-primary btn-sm btn-flat" @click="consolidateAll()">
              Consolidate All
            </button>
            <label class="mb-0">
              <input v-model="buy" name="buy" type="radio" value="1" @change="toggleBalanceSheet('buy')" /> Buy
              Enabled</label>
            <label class="mb-0">
              <input v-model="buy" name="buy" type="radio" value="0" @change="toggleBalanceSheet('buy')" /> Buy
              Disabled</label>
            <label class="mb-0">
              <input v-model="buy" name="buy" type="radio" value="-1" @change="toggleBalanceSheet('buy')" /> Buy
              Both</label>
            <label class="mb-0">
              <input v-model="isOnline" name="isOnline" type="radio" value="1" @change="toggleBalanceSheet('online')" />
              Online</label>
            <label class="mb-0">
              <input v-model="isOnline" name="isOnline" type="radio" value="0"
                @change="toggleBalanceSheet('offline')" />
              Offline</label>
            <input v-model="keyword" aria-label="Search" class="form-control w-150" placeholder="Search..."
              type="text" />
          </div>
        </div>
      </div>
      <div class="box-body no-padding">
        <div v-if="filteredBalanceSheet && filteredBalanceSheet.length && loader === ''" class="footer">
          <pagination :meta="meta" @pageChange="updatePage"></pagination>
        </div>
        <div class="table-responsive">
          <table class="table table-condensed mb-0">
            <thead>
              <tr>
                <th>
                  <div class="flex items-center">
                    <input type="checkbox" v-model="selectAll" name="selectAll" />
                    <div class="pointer" @click="sort('ID')">
                      ID
                      <span v-if="sortKey === 'ID'" class="mx-4">
                        <i class="fa fa-sort"></i>
                      </span>
                    </div>
                  </div>
                </th>
                <th class="pointer" @click="sort('Name')">
                  Asset
                  <span v-if="sortKey === 'Name'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
                <th class="pointer" @click="sort('Balance')">
                  Balance
                  <span v-if="sortKey === 'Balance'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
                <th class="pointer" @click="sort('Actual')">
                  Actual
                  <span v-if="sortKey === 'Actual'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
                <th class="pointer" @click="sort('RealActual')">
                  Real Actual
                  <span v-if="sortKey === 'RealActual'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
                <th class="pointer" @click="sort('LTRBalance')">
                  LTR Balance
                  <span v-if="sortKey === 'LTRBalance'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
                <th class="pointer" @click="sort('VToken')">
                  VToken Balance
                  <span v-if="sortKey === 'VToken'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
                <th class="pointer" @click="sort('SubTotal')">
                  Sub Total
                  <span v-if="sortKey === 'SubTotal'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
                <th class="pointer" @click="sort('SubTotalBTC')">
                  Sub Total BTC
                  <span v-if="sortKey === 'SubTotalBTC'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
                <th class="pointer" @click="sort('TotalBalance')">
                  Total Balance
                  <span v-if="sortKey === 'TotalBalance'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
                <th class="pointer" @click="sort('TotalBalanceBTC')">
                  Total Balance BTC
                  <span v-if="sortKey === 'TotalBalanceBTC'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
                <th>Actions</th>
                <th class="pointer" @click="sort('CreatedOn')">
                  Updated On
                  <span v-if="sortKey === 'CreatedOn'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-if="paginatedBalanceSheetList.length > 0">
                <template v-for="(record, index) in paginatedBalanceSheetList">
                  <tr :key="`record_${index}`" :class="`${record.ID === refreshingRecord ? 'border-left-red' : ''}`">
                    <td class="no-wrap">
                      <input type="checkbox" name="balance" v-model="record.selected" :checked="record.selected" />
                      <router-link :to="{ name: 'payouts', query: { id: record.ID } }" tag="a">
                        <i class="fa fa-money mr-8"></i>
                        {{ record.ID }}
                      </router-link>
                    </td>
                    <td class="no-wrap">
                      <a v-if="record.Online" v-tooltip="{ content: 'Refresh Balance', trigger: 'click hover focus' }"
                        class="pointer ml-8" @click="refreshBalance(record)">
                        <i :class="`fa fa-lg fa-circle-o-notch ${record.isBalanceRefreshing ? 'blink' : ''}`"></i>
                      </a>
                      <a v-if="record.Online"
                        v-tooltip="{ content: 'Refresh Balance & Reload', trigger: 'click hover focus' }"
                        class="pointer ml-8" @click="refreshBalanceAndReload(record)">
                        <i :class="`fa fa-lg fa-refresh ${record.isBalanceReloading ? 'blink' : ''}`"></i>
                      </a>
                      &nbsp;
                      <router-link :to="{ name: 'coin-health-detail', params: { id: record.ID } }" tag="a">
                        <img :src="generateIconUrl(record)" alt="" class="w-48 mx-8" />
                      </router-link>
                      <span class="ml-8">{{ record.Name }}</span>
                      /
                      <a :class="getTextColor(record.Buy)" class="pointer" @click="check(record, index)">
                        {{ record.LongName }}
                      </a>
                      /
                      <a v-if="record.LongName !== 'BTC'"
                        :href="'https://exchange.cointopay.com/tradeview/BTC_' + record.LongName" target="_blank">
                        <i class="fa fa-bitcoin"></i>
                      </a>
                    </td>
                    <td :class="{ 'bg-red': record.Balance < 0 }">
                      <span class="flex align-items-center">
                        <span class="mr-8">
                          <i class="fa fa-copy pointer" @click="toConverter(record.Balance, record.ID)"></i>
                        </span>
                        {{ toFixed(record.Balance) }}
                      </span>
                    </td>
                    <td :class="getBgClass(record.Actual)" class="semi-bold">
                      <div class="flex align-items-center">
                        <span class="mr-8">
                          <i class="fa fa-copy pointer" @click="toConverter(toFixed(record.Actual), record.ID)"></i>
                        </span>
                        {{ toFixed(record.Actual) }}
                      </div>
                    </td>
                    <td :class="getBgClass(record.RealActual)" class="no-wrap">
                      <div class="flex align-items-center justify-between">
                        <div class="flex align-items-center">
                          <span class="mr-8">
                            <i class="fa fa-copy pointer"
                              @click="toConverter(toFixed(record.RealActual), record.ID)"></i>
                          </span>
                          {{ toFixed(record.RealActual) }}
                        </div>
                        <div v-if="record.RealActual >= 0 && isOnline === '1'">
                          <button v-tooltip="{ content: 'Send to FiatBridge', trigger: 'click hover focus' }"
                            :disabled="!record.IsFiatBridge" class="btn btn-primary btn-sm btn-flat ml-8"
                            @click="sendToFiatBridge(record, record.RealActual, index)">F
                          </button>
                        </div>
                      </div>
                    </td>
                    <td :class="getBgLTRClass(record.LTR, record.LTRBalance)" class="no-wrap">
                      <template v-if="record.LTR">
                        <div class="flex align-items-center justify-between">
                          <div class="flex align-items-center">
                            <span class="mr-8">
                              <i class="fa fa-copy pointer"
                                @click="toConverter(toFixed(record.LTRBalance), record.ID)"></i>
                            </span>
                            <a v-if="isOnline" :class="getLTRTextColor(record.LTRBalance)">
                              {{ toFixed(record.LTRBalance) }}
                            </a>
                            <span v-else>{{ toFixed(record.LTRBalance) }}</span>
                          </div>
                          <button v-if="isOnline" v-tooltip="{ content: 'LTR Check', trigger: 'click hover focus' }"
                            :class="`${record.isLTRRefreshing ? 'blinker' : ''}`"
                            class="btn btn-primary btn-sm btn-flat ml-8" @click="ltrCheck(record, index)">
                            <span>L</span>
                            <i v-if="record.isLTRRefreshing" class="fa fa-refresh fa-spin ml-4"></i>
                          </button>
                        </div>
                      </template>
                      <template v-else>LTR Disabled</template>
                    </td>
                    <td :class="getBgClass(record.VToken)" class="no-wrap">
                      <div class="flex align-items-center justify-between">
                        <div class="flex align-items-center">
                          <span class="mr-8">
                            <i class="fa fa-copy pointer" @click="toConverter(toFixed(record.VToken), record.ID)"></i>
                          </span>
                          {{ toFixed(record.VToken) }}
                        </div>
                        <router-link :to="{ name: 'vtokens', query: { id: record.ID } }" class="ml-8" tag="a">
                          <i class="fa fa-search pointer"></i>
                        </router-link>
                      </div>
                    </td>
                    <td :class="getBgClass(record.SubTotal)" class="no-wrap">
                      <div class="flex align-items-center justify-between">
                        <div class="flex align-items-center">
                          <span class="mr-8">
                            <i class="fa fa-copy pointer" @click="toConverter(toFixed(record.SubTotal), record.ID)"></i>
                          </span>
                          {{ toFixed(record.SubTotal) }}
                        </div>
                        <div v-if="record.SubTotal >= 0 && isOnline === '1'">
                          <button v-tooltip="{ content: 'Send to FiatBridge', trigger: 'click hover focus' }"
                            :disabled="!record.IsFiatBridge" class="btn btn-primary btn-sm btn-flat ml-8"
                            @click="sendToFiatBridge(record, record.SubTotal, index)">F
                          </button>
                        </div>
                      </div>
                    </td>
                    <td :class="getBgClass(record.SubTotalBTC)" class="no-wrap">
                      <div class="flex align-items-center">
                        <span class="mr-8">
                          <i class="fa fa-copy pointer" @click="toConverter(toFixed(record.SubTotalBTC), 1)"></i>
                        </span>
                        {{ toFixed(record.SubTotalBTC) }}
                      </div>
                    </td>
                    <td :class="getBgClass(record.TotalBalance)" class="no-wrap">
                      <div class="flex align-items-center justify-between">
                        <div class="flex align-items-center">
                          <span class="mr-8">
                            <i class="fa fa-copy pointer"
                              @click="toConverter(toFixed(record.TotalBalance), record.ID)"></i>
                          </span>
                          {{ toFixed(record.TotalBalance) }}
                        </div>
                        <div v-if="record.TotalBalance > 0 && isOnline === '1'">
                          <button v-tooltip="{ content: 'Send to FiatBridge', trigger: 'click hover focus' }"
                            :disabled="!record.IsFiatBridge" class="btn btn-primary btn-sm btn-flat ml-8"
                            @click="sendToFiatBridge(record, record.TotalBalance, index)">F
                          </button>
                        </div>
                      </div>
                    </td>
                    <td :class="getBgClass(record.TotalBalanceBTC)">
                      <div class="flex align-items-center">
                        <span class="mr-8">
                          <i class="fa fa-copy pointer" @click="toConverter(toFixed(record.TotalBalanceBTC), 1)"></i>
                        </span>
                        {{ toFixed(record.TotalBalanceBTC) }}
                        <span class="ml-8">
                          <i class="fa fa-plus pointer"
                            @click="toConverter(toFixed(record.TotalBalanceBTC), 1, true)"></i>
                        </span>
                      </div>
                    </td>
                    <td class="no-wrap">
                      <template v-if="isOnline === '1'">
                        <button v-tooltip="{ content: 'PoolCheck & Consolidation', trigger: 'click hover focus' }"
                          class="btn btn-info btn-sm btn-flat ml-8"
                          :class="`${record.isConsolidateRefreshing ? 'blinker' : ''}`"
                          @click="poolCheckAndConsolidation(record, index)">
                          <span>C</span>
                          <i v-if="record.isConsolidateRefreshing" class="fa fa-refresh fa-spin ml-4"></i>
                        </button>
                        <button v-tooltip="{ content: 'Buy Currency', trigger: 'click hover focus' }"
                          :disabled="record.FiatBridgeFiatBalance === 0"
                          :class="`btn ${isXChangeAndPoolMasterSame(record) ? 'btn-warning' : 'btn-primary'}
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                   ${record.isBuyRefreshing ? 'blinker' : ''} btn-sm btn-flat ml-8`"
                          @click="buyCurrency(record)">
                          <span>B^</span>
                          <i v-if="record.isBuyRefreshing" class="fa fa-refresh fa-spin ml-4"></i>
                        </button>
                        <button v-tooltip="{ content: 'Sell Currency', trigger: 'click hover focus' }"
                          :disabled="record.TotalBalance <= 0 && record.FiatBridgeCoinBalance === 0"
                          :class="`btn ${isXChangeAndPoolMasterSame(record) ? 'btn-warning' : 'btn-primary'}                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                           ${record.isSellRefreshing ? 'blinker' : ''} btn-sm btn-flat ml-8`"
                          @click="sellCurrency(record)">
                          <span>S^</span>
                          <i v-if="record.isSellRefreshing" class="fa fa-refresh fa-spin ml-4"></i>
                        </button>
                        <button v-tooltip="{ content: 'Withdraw Fiat / Crypto', trigger: 'click hover focus' }"
                          :disabled="record.FiatBridgeFiatBalance === 0" class="btn btn-primary btn-sm btn-flat ml-8"
                          @click="withdrawFiat(record, record.FiatBridgeFiatBalance, index)">
                          <span v-if="record.FiatBridgeFiatName">{{ record.FiatBridgeFiatName + ' - ' }}</span>
                          W^
                        </button>
                        <button v-if="isNegative(record)"
                          v-tooltip="{ content: 'Send to FiatBridge', trigger: 'click hover focus' }"
                          :disabled="!record.IsFiatBridge" class="btn btn-primary btn-sm btn-flat ml-8"
                          @click="sendToFiatBridge(record, Math.abs(record.Balance) - Math.abs(record.SubTotal), index)">
                          F
                        </button>
                        <button :disabled="isTBDisabled(record)"
                          v-tooltip="{ content: 'Send to TradeBot', trigger: 'click hover focus' }"
                          @click="sendToTradeBot(record)" class="btn btn-primary btn-sm btn-flat ml-8">TB</button>
                      </template>
                      <template v-else>
                        <button class="btn btn-primary btn-flat btn-sm" disabled type="button">Actions Offline</button>
                      </template>
                    </td>
                    <td class="no-wrap">{{ record.CreatedOn }}</td>
                  </tr>
                  <tr :key="'detail_' + index" style="background-color: #f3f3f3;">
                    <td colspan="13">
                      <span v-if="record.XChangeID">{{ record.XChangeID }}</span>
                      <span v-if="record.XChangeID">&nbsp;||&nbsp;</span>
                      <span v-if="record.PoolMaster">PM: {{ record.PoolMaster.replace('-', ' ') }}</span>
                      <span v-if="record.PoolMaster">&nbsp;||&nbsp;</span>
                      <span v-if="record.XChangeName">FB: {{ record.FiatBridgeUsername }} {{ record.XChangeName
                        }}</span>
                      <span v-if="record.XChangeName">&nbsp;||&nbsp;</span>
                      <span v-if="record.TradeBridge">TB: {{ record.TradeBridge.replace('-', ' ') }}</span>
                      <span v-if="record.TradeBridge">&nbsp;||&nbsp;</span>
                      <span>SecureCloud:
                        <span class="text-bold">{{ record.SecureCloud }}</span>
                      </span>
                      <span>&nbsp;||&nbsp;</span>
                      <span>Sell: <a :href="`${siteUrl}/admin/trade-flex#search?altCoinId=${record.ID}&tft=2`"
                          target="_blank">
                          {{ toFixed(record.TradeFlexSell) }}</a>
                      </span>
                      <span>&nbsp;||&nbsp;</span>
                      <span>Buy: <a :href="`${siteUrl}/admin/trade-flex#search?altCoinId=${record.ID}&tft=1`"
                          target="_blank">
                          {{ toFixed(record.TradeFlexBuy) }}</a>
                      </span>
                    </td>
                  </tr>
                </template>
              </template>
              <template v-else>
                <tr>
                  <td colspan="13">No record found!</td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <div v-if="filteredBalanceSheet && filteredBalanceSheet.length && loader === ''" class="footer">
          <pagination :meta="meta" @pageChange="updatePage"></pagination>
        </div>
      </div>
    </div>
    <!-- PoolCheckAndConsolidateModal -->
    <PoolCheckAndConsolidateModal />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  SAVE_BALANCE_SHEET_BUY_BOTH_OFFLINE,
  SAVE_BALANCE_SHEET_BUY_BOTH_ONLINE,
  SAVE_BALANCE_SHEET_BUY_DISABLED_OFFLINE,
  SAVE_BALANCE_SHEET_BUY_DISABLED_ONLINE,
  SAVE_BALANCE_SHEET_BUY_ENABLED_OFFLINE,
  SAVE_BALANCE_SHEET_BUY_ENABLED_ONLINE,
  SAVE_CONVERTER,
  STOP_LOADING,
} from "@/store/keys";
import Pagination from "@/components/Pagination";
import PoolCheckAndConsolidateModal from "@/components/balance-sheet/Modals/PoolCheckAndConsolidateModal";
import { generateIconUrl, logout, saveNotification, toFixed } from "@/lib/utils";
import findIndex from "lodash/findIndex";
// eslint-disable-next-line import/no-webpack-loader-syntax
import ConsolidateAll from "worker-loader!@/consolidateAll.js";
// eslint-disable-next-line import/no-webpack-loader-syntax
import BalanceRefreshAll from "worker-loader!@/balanceRefreshAll.js";

export default {
  name: "BalanceSheet",
  components: {
    Pagination,
    PoolCheckAndConsolidateModal,
  },
  data() {
    return {
      siteUrl: `${window.location.protocol}//${window.location.host}`,
      balanceSheet: [],
      meta: {
        total: 0,
        page: 1,
        offset: 0,
        limit: Number.parseInt(process.env.VUE_APP_LIMIT, 10),
      },
      loader: "",
      refreshLoader: "",
      keyword: "",
      isOnline: "1",
      buy: "1",
      sortKey: "RealActualMultiplySell",
      order: "desc",
      altCoinID: null,
      isEventAttached: false,
      selectAll: false,
      paginatedBalanceSheetList: [],
      refreshingRecord: "",
      isAlert: false,
      worker: null,
      supportUser: null,
    };
  },
  computed: {
    ...mapGetters([
      "jwtToken",
      "currentUser",
      "converter",
      "balanceSheetBuyEnabledOnlineData",
      "balanceSheetBuyDisabledOnlineData",
      "balanceSheetBuyEnabledOfflineData",
      "balanceSheetBuyDisabledOfflineData",
      "balanceSheetBuyBothOnlineData",
      "balanceSheetBuyBothOfflineData",
    ]),
    filteredBalanceSheet() {
      return this.balanceSheet.filter(bSheet => {
        if (this.altCoinID) {
          return bSheet.ID.toString() === this.altCoinID.toString();
        }
        if (this.keyword) {
          const keyword = this.keyword.toString().trim().toLowerCase();
          return this._.includes(bSheet.Name.toLowerCase(), keyword) ||
            this._.includes(bSheet.ID.toString(), keyword) ||
            this._.includes(bSheet.LongName.toLowerCase(), keyword) ||
            this._.includes(bSheet.Balance.toString(), keyword) ||
            this._.includes(bSheet.Actual.toString(), keyword) ||
            this._.includes(bSheet.RealActual.toString(), keyword) ||
            this._.includes(bSheet.RealActualMultiplySell.toString(), keyword) ||
            this._.includes(bSheet.LTRBalanceMinusVToken.toString(), keyword) ||
            this._.includes(bSheet.RealActualMinusVToken.toString(), keyword) ||
            this._.includes(bSheet.XChangeID && bSheet.XChangeID, keyword) ||
            this._.includes(bSheet.RegisteredEmail && bSheet.RegisteredEmail.toLowerCase(), keyword) ||
            this._.includes(bSheet.XChangeServer && bSheet.XChangeServer.toLowerCase(), keyword);
        }
        return true;
      });
    },
    paginatedBalanceSheet() {
      if (this.filteredBalanceSheet.length > 0) {
        let filteredBalanceSheet = this.filteredBalanceSheet;
        // Sort if any
        if (this.sortKey) {
          filteredBalanceSheet = filteredBalanceSheet.map(b => {
            return {
              ...b,
              Actual: Number.parseFloat(b.Actual),
              RealActual: Number.parseFloat(b.RealActual),
              Balance: Number.parseFloat(b.Balance),
              RealActualMultiplySell: Number.parseFloat(b.RealActualMultiplySell),
            };
          });
          filteredBalanceSheet = this._.orderBy(filteredBalanceSheet, [this.sortKey], [this.order]);
        }
        const offset = this.meta.page > 0 ? this.meta.page - 1 : 0;
        return filteredBalanceSheet.slice(offset * this.meta.limit, this.meta.page * this.meta.limit);
      }
      return this.filteredBalanceSheet;
    },
    selectedCount() {
      return this.paginatedBalanceSheetList.filter(b => b.selected).length;
    },
  },
  watch: {
    filteredBalanceSheet(value) {
      this.meta = Object.assign({}, this.meta, {
        page: 1,
        offset: 0,
        total: value.length,
      });
    },
    paginatedBalanceSheet(val) {
      this.paginatedBalanceSheetList = val.map(item => {
        return {
          ...item,
          isBalanceRefreshing: false,
          isBalanceReloading: false,
          isSellRefreshing: false,
          isBuyRefreshing: false,
          isConsolidateRefreshing: false,
          isLTRRefreshing: false,
        };
      });
    },
    selectAll(val) {
      this.paginatedBalanceSheetList = this.paginatedBalanceSheetList.map(b => {
        return {
          ...b,
          selected: val,
        };
      });
    },
  },
  methods: {
    toConverter(amount, altCoinID, sum = false) {
      const form = { ...this.converter };
      if (sum && !isNaN(form.source)) {
        form.source = Number(form.source) + Number(amount);
      } else {
        form.source = amount;
      }
      form.sourceAltCoinId = altCoinID;
      this.$store.commit(SAVE_CONVERTER, form);
      this.copyToClipboard(amount);
    },
    copyToClipboard(val) {
      const self = this;
      navigator.clipboard.writeText(val).then(() => {
        self.$toast.fire("", "Copied to clipboard: " + val, "success");
        saveNotification("Copied to clipboard: " + val);
      }, (err) => {
        console.error("Async: Could not copy text: ", err);
      });
    },
    isNegative(record) {
      return !!(record.Actual < 0 || record.RealActual < 0 || record.TotalBalance < 0 ||
        record.SubTotalBTC < 0 || record.SubTotal < 0 || record.TotalBalanceBTC < 0);
    },
    toFixed(val, dec = 8) {
      return toFixed(val, dec);
    },
    generateIconUrl(record) {
      return generateIconUrl(record.Name);
    },
    extractPM(val) {
      if (val) {
        const parts = val.split("-");
        return parts[parts.length - 1];
      }
      return "";
    },
    isXChangeAndPoolMasterSame(record) {
      const poolMaster = this.extractPM(record.PoolMaster);
      return poolMaster && record.XChangeName && poolMaster.toLowerCase() === record.XChangeName.toLowerCase();
    },
    toggleBalanceSheet(type = "buy") {
      if (type === "buy") {
        if (this.buy === "1") {
          if (this.isOnline === "1") {
            this.balanceSheet = (this.balanceSheetBuyEnabledOnlineData.length > 0)
              ? this.balanceSheetBuyEnabledOnlineData : [];
          } else {
            this.balanceSheet = (this.balanceSheetBuyEnabledOfflineData.length > 0)
              ? this.balanceSheetBuyEnabledOfflineData : [];
          }
        } else if (this.buy === "0") {
          if (this.isOnline === "1") {
            this.balanceSheet = (this.balanceSheetBuyDisabledOnlineData.length > 0)
              ? this.balanceSheetBuyDisabledOnlineData : [];
          } else {
            this.balanceSheet = (this.balanceSheetBuyDisabledOfflineData.length > 0)
              ? this.balanceSheetBuyDisabledOfflineData : [];
          }
        } else {
          if (this.isOnline === "1") {
            this.balanceSheet = (this.balanceSheetBuyBothOnlineData.length > 0)
              ? this.balanceSheetBuyBothOnlineData : [];
          } else {
            this.balanceSheet = (this.balanceSheetBuyBothOfflineData.length > 0)
              ? this.balanceSheetBuyBothOfflineData : [];
          }
        }
      } else {
        if (this.isOnline === "1") {
          if (this.buy === "1") {
            this.balanceSheet = (this.balanceSheetBuyEnabledOnlineData.length > 0)
              ? this.balanceSheetBuyEnabledOnlineData : [];
          } else if (this.buy === "0") {
            this.balanceSheet = (this.balanceSheetBuyDisabledOnlineData.length > 0)
              ? this.balanceSheetBuyDisabledOnlineData : [];
          } else {
            this.balanceSheet = (this.balanceSheetBuyBothOnlineData.length > 0)
              ? this.balanceSheetBuyBothOnlineData : [];
          }
        } else {
          if (this.buy === "1") {
            this.balanceSheet = (this.balanceSheetBuyEnabledOfflineData.length > 0)
              ? this.balanceSheetBuyEnabledOfflineData : [];
          } else if (this.buy === "0") {
            this.balanceSheet = (this.balanceSheetBuyDisabledOfflineData.length > 0)
              ? this.balanceSheetBuyDisabledOfflineData : [];
          } else {
            this.balanceSheet = (this.balanceSheetBuyBothOfflineData.length > 0)
              ? this.balanceSheetBuyBothOfflineData : [];
          }
        }
      }
      if (this.balanceSheet.length === 0) {
        this.getBalanceSheet();
      }
    },
    getBalanceSheet(record = null) {
      if (!record) {
        this.loader = "<i class=\"fa fa-refresh fa-spin mr-8\"></i>Loading...";
      }
      const apiBaseUrl = process.env.VUE_APP_ADMIN_API_URL;
      this.$http.get(`${apiBaseUrl}/?Call=PayoutsInfoOffline`, {
        headers: {
          Authorization: "Bearer " + this.jwtToken,
        },
        params: {
          Online: this.isOnline,
          Buy: this.buy,
          ID: record ? record.ID : null,
        },
      }).then(response => {
        if (!record) {
          this.loader = "";
        } else {
          record.isBalanceReloading = false;
          const index = findIndex(this.paginatedBalanceSheetList, { ID: record.ID });
          this.paginatedBalanceSheetList.splice(index, 1, record);
        }
        this.selectAll = false;
        if (response.status === 200) {
          if (record) {
            const index = this._.findIndex(this.balanceSheet, { ID: record.ID });
            if (index !== -1) {
              this.balanceSheet.splice(index, 1, response.data.data[0]);
            }
          } else {
            this.balanceSheet = response.data.data;
            if (this.balanceSheet.length) {
              this.balanceSheet = this.balanceSheet.map(b => {
                return {
                  ...b,
                  selected: false,
                };
              });
            }
            this.meta = Object.assign({}, this.meta, {
              total: this.balanceSheet.length,
              page: 1,
              offset: 0,
            });
          }
          if (this.buy === "1") {
            if (this.isOnline === "1") {
              this.$store.commit(SAVE_BALANCE_SHEET_BUY_ENABLED_ONLINE, this.balanceSheet);
            } else {
              this.$store.commit(SAVE_BALANCE_SHEET_BUY_ENABLED_OFFLINE, this.balanceSheet);
            }
          } else if (this.buy === "0") {
            if (this.isOnline === "1") {
              this.$store.commit(SAVE_BALANCE_SHEET_BUY_DISABLED_ONLINE, this.balanceSheet);
            } else {
              this.$store.commit(SAVE_BALANCE_SHEET_BUY_DISABLED_OFFLINE, this.balanceSheet);
            }
          } else {
            if (this.isOnline === "1") {
              this.$store.commit(SAVE_BALANCE_SHEET_BUY_BOTH_ONLINE, this.balanceSheet);
            } else {
              this.$store.commit(SAVE_BALANCE_SHEET_BUY_BOTH_OFFLINE, this.balanceSheet);
            }
          }
        }
      }).catch(error => {
        if (!record) {
          this.loader = "";
        } else {
          record.isBalanceReloading = false;
          const index = findIndex(this.paginatedBalanceSheetList, { ID: record.ID });
          this.paginatedBalanceSheetList.splice(index, 1, record);
        }
        this.$toast.fire("", error.data.message, "error");
      });
    },
    getBuyDisabledBalanceSheet() {
      const apiBaseUrl = process.env.VUE_APP_ADMIN_API_URL;
      this.$http.get(`${apiBaseUrl}/?Call=PayoutsInfoOffline`, {
        headers: {
          Authorization: "Bearer " + this.jwtToken,
        },
        params: {
          Online: 1,
          Buy: 0,
          ID: null,
        },
      }).then(response => {
        this.loader = "";
        if (response.status === 200) {
          let balanceSheet = response.data.data;
          if (balanceSheet.length) {
            balanceSheet = balanceSheet.map(b => {
              return {
                ...b,
                selected: false,
              };
            });
          }
          this.$store.commit(SAVE_BALANCE_SHEET_BUY_DISABLED_ONLINE, balanceSheet);
        }
      }).catch(error => {
        this.loader = "";
        this.$toast.fire("", error.data.message, "error");
      });
    },
    sort(key) {
      if (key === this.sortKey) {
        this.order = this.order === "asc" ? "desc" : "asc";
      }
      this.sortKey = key;
    },
    updatePage(page) {
      const offset = page > 0 ? page - 1 : 0;
      this.meta = Object.assign({}, this.meta, {
        page: page,
        offset: offset * this.meta.limit,
      });
    },
    getTextColor(online) {
      return !online ? "text-orange" : "text-blue";
    },
    getLTRTextColor(val) {
      return val >= 0 ? "text-success" : "text-danger";
    },
    getBgClass(val, equal = true) {
      if (equal) {
        return val >= 0 ? "bg-success" : "bg-danger";
      } else {
        return val > 0 ? "bg-success" : (val < 0 ? "bg-danger" : "");
      }
    },
    getBgLTRClass(ltr, val) {
      return ltr ? (val >= 0 ? "bg-success" : "bg-danger") : "bg-tile-yellow";
    },
    isTBDisabled(record) {
      if (record.Balance > 0) {
        return false;
      } else if (record.TotalBalance > 0 && record.FiatBridgeCoinBalance > 0) {
        return false;
      }
      return true;
    },
    // Actions
    async validate(text = "", balance = "", target = "", decimalScale = 8) {
      this.isAlert = true;
      if (balance !== "") {
        return await this.$swal.fire({
          text: text,
          icon: "warning",
          input: "text",
          inputValue: toFixed(balance, decimalScale),
          showCancelButton: true,
          confirmButtonColor: "#00a65a",
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          focusCancel: true,
          backdrop: true,
          inputValidator: (value) => {
            if (!value || value <= 0) {
              return "Balance should be greater than 0";
            } else if (target !== "" && value > target) {
              return "Balance can not be greater than " + toFixed(target);
            }
          },
        }).then(({ value }) => {
          this.isAlert = false;
          return value;
        });
      } else {
        return await this.$swal.fire({
          text: text,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#00a65a",
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          focusCancel: true,
        }).then(({ value }) => {
          this.isAlert = false;
          return !!(value && value === true);
        });
      }
    },
    refreshBalance(record = null) {
      if (!record) {
        this.refreshLoader = "<i class=\"fa fa-refresh fa-spin mr-8\"></i>Refreshing balance...";
      }
      const apiBaseUrl = process.env.VUE_APP_API_URL;
      let payload = {
        Name: "B",
        Secure: "AA",
        Buy: this.buy,
      };
      if (record) {
        payload = Object.assign(payload, {
          AltCoinID: record.ID,
        });
        record.isBalanceRefreshing = true;
        const index = findIndex(this.paginatedBalanceSheetList, { ID: record.ID });
        this.paginatedBalanceSheetList.splice(index, 1, record);
      } else {
        payload = Object.assign(payload, {
          AltCoinID: "",
          BalanceRefresh: "",
        });
      }
      this.$http.get(`${apiBaseUrl}/GetInfo`, {
        params: payload,
        withCredentials: true,
      }).then(response => {
        if (!record) {
          this.refreshLoader = "";
        } else {
          record.isBalanceRefreshing = false;
          const index = findIndex(this.paginatedBalanceSheetList, { ID: record.ID });
          this.paginatedBalanceSheetList.splice(index, 1, record);
        }
        if (response.data.status_code === 200) {
          if (response.data.result.toLowerCase() === "success") {
            if (!this.isAlert) {
              this.$toast.fire("", response.data.message, "success");
            }
            saveNotification(response.data.message);
          }
        } else if (response.data.status_code === 400) {
          if (response.data.message.toLowerCase() === "no permission") {
            logout();
          }
        }
      }).catch(error => {
        if (!record) {
          this.refreshLoader = "";
        } else {
          record.isBalanceRefreshing = false;
          const index = findIndex(this.paginatedBalanceSheetList, { ID: record.ID });
          this.paginatedBalanceSheetList.splice(index, 1, record);
        }
        console.log(error);
      });
    },
    refreshBalanceAndReload(record = null) {
      if (!record) {
        this.loader = "<i class=\"fa fa-refresh fa-spin mr-8\"></i>Please wait...";
        if (!this.worker) {
          this.worker = new BalanceRefreshAll();
        }
        this.worker.addEventListener("message", (e) => {
          if (e.data.action === "fetchBalanceRefreshAllComplete") {
            this.loader = "";
            if (!this.isAlert) {
              this.$toast.fire("", e.data.message, "success");
            }
            saveNotification(e.data.message);
            this.getBalanceSheet(record);
            // Stop worker
            this.worker.postMessage("stop");
            this.worker = null;
          } else if (e.data.action === "fetchBalanceRefreshAllError") {
            this.loader = "";
            this.$toast.fire("", e.data.error, "error");
            if (e.data.error === "No Permission") {
              logout();
            }
          }
        });

        this.worker.postMessage({
          action: "fetchBalanceRefreshAll",
          isBuy: this.buy,
          jwtToken: this.jwtToken,
        });
      } else {
        record.isBalanceReloading = true;
        const index = findIndex(this.paginatedBalanceSheetList, { ID: record.ID });
        this.paginatedBalanceSheetList.splice(index, 1, record);

        const apiBaseUrl = process.env.VUE_APP_API_URL;
        const payload = {
          Name: "B",
          Secure: "AA",
          Buy: this.buy,
          AltCoinID: record.ID,
        };
        this.$http.get(`${apiBaseUrl}/GetInfo`, {
          params: payload,
          withCredentials: true,
        }).then(response => {
          if (response.data.status_code === 200) {
            if (response.data.result.toLowerCase() === "success") {
              if (!this.isAlert) {
                this.$toast.fire("", response.data.message, "success");
              }
              saveNotification(response.data.message);
              this.getBalanceSheet(record);
              if (this.isOnline === 1 && this.buy === 1) {
                this.refreshBuyDisabledCoinsBalance();
              }
            }
          } else if (response.data.status_code === 400) {
            record.isBalanceReloading = false;
            const index = findIndex(this.paginatedBalanceSheetList, { ID: record.ID });
            this.paginatedBalanceSheetList.splice(index, 1, record);
            if (response.data.message.toLowerCase() === "no permission") {
              logout();
            }
          }
        }).catch(error => {
          record.isBalanceReloading = false;
          const index = findIndex(this.paginatedBalanceSheetList, { ID: record.ID });
          this.paginatedBalanceSheetList.splice(index, 1, record);
          console.log(error);
        });
      }
    },
    consolidateAll() {
      this.loader = "<i class=\"fa fa-refresh fa-spin mr-8\"></i>Please wait...";
      if (!this.worker) {
        this.worker = new ConsolidateAll();
      }
      this.worker.addEventListener("message", (e) => {
        if (e.data.action === "fetchConsolidateAllComplete") {
          this.loader = "";
          if (!this.isAlert) {
            this.$toast.fire("", e.data.message, "success");
          }
          saveNotification(e.data.message);
          this.getBalanceSheet();
          if (this.isOnline === 1 && this.buy === 1) {
            this.refreshBuyDisabledCoinsBalance();
          }
          // Stop worker
          this.worker.postMessage("stop");
          this.worker = null;
        } else if (e.data.action === "fetchConsolidateAllError") {
          this.loader = "";
          this.$toast.fire("", e.data.error, "error");
          if (e.data.error === "No Permission") {
            logout();
          }
        }
      });

      this.worker.postMessage({
        action: "fetchConsolidateAll",
        isBuy: this.buy,
        jwtToken: this.jwtToken,
      });
    },
    async refreshSelectedBalance(isReload = false) {
      const apiBaseUrl = process.env.VUE_APP_API_URL;
      const list = this.paginatedBalanceSheetList.filter(b => b.selected);
      let i = 1;
      for (const record of list) {
        this.refreshingRecord = record.ID;
        this.refreshLoader = `<i class="fa fa-refresh fa-spin mr-8"></i> ${i} / ${list.length} ${record.LongName} `;
        const response = await this.$http.get(`${apiBaseUrl}/GetInfo`, {
          params: {
            Name: "B",
            Secure: "AA",
            Buy: this.buy,
            AltCoinID: record.ID,
          },
          withCredentials: true,
        });
        this.refreshLoader = "";
        if (response.data.status_code === 200) {
          if (response.data.result.toLowerCase() === "success") {
            if (!this.isAlert) {
              this.$toast.fire("", response.data.message, "success");
            }
            saveNotification(response.data.message);
          }
        } else if (response.data.status_code === 400) {
          if (response.data.message.toLowerCase() === "no permission") {
            logout();
          }
        }
        i++;
      }
      this.paginatedBalanceSheetList = this.paginatedBalanceSheetList.map(b => {
        return {
          ...b,
          selected: false,
          isBalanceRefreshing: false,
          isBalanceReloading: false,
          isSellRefreshing: false,
          isBuyRefreshing: false,
          isConsolidateRefreshing: false,
          isLTRRefreshing: false,
        };
      });
      this.refreshingRecord = "";
      if (isReload) {
        this.getBalanceSheet();
        if (this.isOnline === 1 && this.buy === 1) {
          this.refreshBuyDisabledCoinsBalance();
        }
      }
    },
    refreshBuyDisabledCoinsBalance() {
      const apiBaseUrl = process.env.VUE_APP_API_URL;
      const payload = {
        Name: "B",
        Secure: "AA",
        Buy: 0,
        AltCoinID: "",
        BalanceRefresh: "",
      };
      this.$http.get(`${apiBaseUrl}/GetInfo`, {
        params: payload,
        withCredentials: true,
      }).then(response => {
        this.loader = "";
        if (response.data.status_code === 200) {
          if (response.data.result.toLowerCase() === "success") {
            this.$toast.fire("", response.data.message, "success");
            saveNotification(response.data.message);
            this.getBuyDisabledBalanceSheet();
          }
        } else if (response.data.status_code === 400) {
          if (response.data.message.toLowerCase() === "no permission") {
            logout();
          }
        }
      }).catch(error => {
        this.loader = "";
        console.log(error);
      });
    },
    async check(record, index) {
      const result = await this.validate("Refresh balance of AltCoin");
      if (result) {
        this.startLoader();
        const apiBaseUrl = process.env.VUE_APP_API_URL;
        const payload = {
          Name: "B",
          AltCoinID: record.ID,
          Secure: "AA",
        };
        this.$http.get(`${apiBaseUrl}/GetInfo`, {
          params: payload,
          withCredentials: true,
        }).then(response => {
          this.stopLoader();
          if (response.data.status_code === 200) {
            if (response.data.result.toLowerCase() === "success") {
              this.$toast.fire("", response.data.message, "success");
              saveNotification(response.data.message);
            }
          } else if (response.data.status_code === 400) {
            if (response.data.message.toLowerCase() === "no permission") {
              logout();
            }
          }
        }).catch(error => {
          this.stopLoader();
          console.log(error);
        });
      }
    },
    async consolidateSelected(isReload = true) {
      const apiBaseUrl = process.env.VUE_APP_API_URL;
      const list = this.paginatedBalanceSheetList.filter(b => b.selected);
      let i = 1;
      for (const record of list) {
        this.refreshingRecord = record.ID;
        this.refreshLoader = `<i class="fa fa-refresh fa-spin mr-8"></i> ${i} / ${list.length} ${record.LongName} `;
        const response = await this.$http.get(`${apiBaseUrl}/GetInfo`, {
          params: {
            Name: "B",
            Secure: "AA",
            Buy: this.buy,
            AltCoinID: record.ID,
            Consolidate: 1,
            PoolCheck: 1,
            BalanceRefresh: "",
          },
          withCredentials: true,
        });
        this.refreshLoader = "";
        if (response.data.status_code === 200) {
          if (response.data.result.toLowerCase() === "success") {
            if (!this.isAlert) {
              this.$toast.fire("", response.data.message, "success");
            }
            saveNotification(response.data.message);
          }
        } else if (response.data.status_code === 400) {
          if (response.data.message.toLowerCase() === "no permission") {
            logout();
          }
        }
        i++;
      }
      this.paginatedBalanceSheetList = this.paginatedBalanceSheetList.map(b => {
        return {
          ...b,
          selected: false,
          isBalanceRefreshing: false,
          isBalanceReloading: false,
          isSellRefreshing: false,
          isBuyRefreshing: false,
          isConsolidateRefreshing: false,
          isLTRRefreshing: false,
        };
      });
      this.refreshingRecord = "";
      if (isReload) {
        this.getBalanceSheet();
        if (this.isOnline === 1 && this.buy === 1) {
          this.refreshBuyDisabledCoinsBalance();
        }
      }
    },
    async poolCheckAndConsolidation(record, index) {
      this.$bus.$emit("poolCheckAndConsolidateModal", {
        callback: (action) => {
          if (action === "cancel") {
            return;
          }
          record.isConsolidateRefreshing = true;
          this.paginatedBalanceSheetList.splice(index, 1, record);
          const apiBaseUrl = process.env.VUE_APP_API_URL;
          let payload = {
            Name: "B",
            AltCoinID: record.ID,
            Secure: "AA",
            PoolCheck: 1,
          };
          if (action === "poolMaster" || action === "fiatBridge") {
            payload = {
              ...payload,
              Consolidate: action === "poolMaster" ? 1 : 3,
            };
          }
          this.$http.get(`${apiBaseUrl}/GetInfo`, {
            params: payload,
            withCredentials: true,
          }).then(response => {
            record.isConsolidateRefreshing = false;
            this.paginatedBalanceSheetList.splice(index, 1, record);
            if (response.data.status_code === 200) {
              if (response.data.result.toLowerCase() === "success") {
                this.$toast.fire("", response.data.message, "success");
                saveNotification(response.data.message);
              }
            } else if (response.data.status_code === 400) {
              if (response.data.message.toLowerCase() === "no permission") {
                logout();
              }
            }
          }).catch(error => {
            record.isConsolidateRefreshing = false;
            this.paginatedBalanceSheetList.splice(index, 1, record);
            console.log(error);
          });
        },
      });
    },
    async sendToFiatBridge(record, balance, index) {
      const value = await this.validate("Send to FiatBridge", balance);
      if (value && value > 0) {
        this.startLoader();
        const apiBaseUrl = process.env.VUE_APP_API_URL;
        const payload = {
          Name: "B",
          AltCoinID: record.ID,
          Secure: "AA",
          PoolCheck: 1,
          Consolidate: 2,
          Amount: this.toFixed(value),
        };
        this.$http.get(`${apiBaseUrl}/GetInfo`, {
          params: payload,
          withCredentials: true,
        }).then(response => {
          this.stopLoader();
          if (response.data.status_code === 200) {
            if (response.data.result.toLowerCase() === "success") {
              this.$toast.fire("", response.data.message, "success");
              saveNotification(response.data.message);
            }
          } else if (response.data.status_code === 400) {
            if (response.data.message.toLowerCase() === "no permission") {
              logout();
            }
          }
        }).catch(error => {
          this.stopLoader();
          console.log(error);
        });
      }
    },
    generateRandomString(length) {
      const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let result = "";
      const charactersLength = characters.length;
      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    },
    async sendToTradeBot(record) {
      const value = await this.validate("Send to TradeBot", record.TotalBalance);
      if (value && value > 0) {
        this.startLoader();
        const apiBaseUrl = process.env.VUE_APP_API_URL;
        // Create vToken
        const rand = this.generateRandomString(32);
        await this.$http.get(`${apiBaseUrl}/v2REAPI`, {
          params: {
            Call: "VTOKENInsert",
            MerchantID: this.currentUser.ID,
            APIKey: this.currentUser.APIKey,
            AltCoinID: record.ID,
            Amount: value,
            Token: `PLACEHOLDER_${record.LongName}_${rand}`,
            output: "json",
          },
        });
        // Claim vToken
        await this.$http.get(`${apiBaseUrl}/v2REAPI`, {
          params: {
            Call: "VTOKEN",
            MerchantID: this.supportUser.ID,
            APIKey: this.supportUser.APIKey,
            AltCoinID: record.ID,
            output: "json",
            Amount: value,
            Direction: 0,
            Token: `PLACEHOLDER_${record.LongName}_${rand}`,
            V2: "",
          },
        });
        this.stopLoader();
        // Redirect to Spawn Bot and Select support merchant
        this.$router.push({
          name: "spawn-bot",
          query: {
            merchant: 1,
            symbol: `${record.LongName}-USDT`,
          },
        });
      }
    },
    async buyCurrency(record) {
      const balance = this.toFixed(Math.abs(record.TotalBalance), record.DecimalScale);
      const value = await this.validate("Buy currency", balance, "", record.DecimalScale);
      if (value && value > 0) {
        record.isBuyRefreshing = true;
        let index = findIndex(this.paginatedBalanceSheetList, { ID: record.ID });
        this.paginatedBalanceSheetList.splice(index, 1, record);
        const apiBaseUrl = process.env.VUE_APP_API_URL;
        const payload = {
          Name: "B",
          AltCoinID: record.ID,
          Secure: "AA",
          FIATBUYSELLEUR: 0,
          Amount: this.toFixed(value, record.DecimalScale),
          output: "json",
        };
        this.$http.get(`${apiBaseUrl}/GetInfo`, {
          params: payload,
          withCredentials: true,
        }).then(response => {
          record.isBuyRefreshing = false;
          index = findIndex(this.paginatedBalanceSheetList, { ID: record.ID });
          this.paginatedBalanceSheetList.splice(index, 1, record);
          if (response.data.status_code === 200) {
            if (response.data.result.toLowerCase() === "success") {
              this.$toast.fire("", response.data.message, "success");
              saveNotification(response.data.message);
            }
          } else if (response.data.status_code === 400) {
            if (response.data.message.toLowerCase() === "no permission") {
              logout();
            }
          }
        }).catch(error => {
          record.isBuyRefreshing = false;
          index = findIndex(this.paginatedBalanceSheetList, { ID: record.ID });
          this.paginatedBalanceSheetList.splice(index, 1, record);
          console.log(error);
        });
      }
    },
    async sellCurrency(record) {
      const isSame = this.isXChangeAndPoolMasterSame(record);
      let balance = isSame
        ? record.TotalBalance
        : ((record.FiatBridgeCoinBalance < record.TotalBalance || record.TotalBalance < 0)
          ? record.FiatBridgeCoinBalance : record.TotalBalance);
      balance = this.toFixed(balance, record.DecimalScale);
      const value = await this.validate("Sell currency", balance, "", record.DecimalScale);
      if (value && value > 0) {
        record.isSellRefreshing = true;
        let index = findIndex(this.paginatedBalanceSheetList, { ID: record.ID });
        this.paginatedBalanceSheetList.splice(index, 1, record);
        const apiBaseUrl = process.env.VUE_APP_API_URL;
        const payload = {
          Name: "B",
          AltCoinID: record.ID,
          Secure: "AA",
          FIATBUYSELLEUR: 1,
          Amount: this.toFixed(value, record.DecimalScale),
          output: "json",
        };
        this.$http.get(`${apiBaseUrl}/GetInfo`, {
          params: payload,
          withCredentials: true,
        }).then(response => {
          record.isSellRefreshing = false;
          index = findIndex(this.paginatedBalanceSheetList, { ID: record.ID });
          this.paginatedBalanceSheetList.splice(index, 1, record);
          if (response.data.status_code === 200) {
            if (response.data.result.toLowerCase() === "success") {
              this.$toast.fire("", response.data.message, "success");
              saveNotification(response.data.message);
            }
          } else if (response.data.status_code === 400) {
            if (response.data.message.toLowerCase() === "no permission") {
              logout();
            }
          }
        }).catch(error => {
          record.isSellRefreshing = false;
          index = findIndex(this.paginatedBalanceSheetList, { ID: record.ID });
          this.paginatedBalanceSheetList.splice(index, 1, record);
          console.log(error);
        });
      }
    },
    async withdrawFiat(record, balance, index) {
      const value = await this.validate("Withdraw fiat", balance, balance);
      if (value && value > 0) {
        this.startLoader();
        const apiBaseUrl = process.env.VUE_APP_API_URL;
        const payload = {
          Name: "B",
          AltCoinID: record.ID,
          Secure: "AA",
          FIATBUYSELLWITHDRAWEUR: 1,
          Amount: this.toFixed(value),
          output: "json",
        };
        this.$http.get(`${apiBaseUrl}/GetInfo`, {
          params: payload,
          withCredentials: true,
        }).then(response => {
          this.stopLoader();
          if (response.data.status_code === 200) {
            if (response.data.result.toLowerCase() === "success") {
              this.$toast.fire("", response.data.message, "success");
              saveNotification(response.data.message);
            }
          } else if (response.data.status_code === 400) {
            if (response.data.message.toLowerCase() === "no permission") {
              logout();
            }
          }
        }).catch(error => {
          this.stopLoader();
          console.log(error);
        });
      }
    },
    async poloniexLendingCheck(record, index) {
      const result = await this.validate("Poloniex lending check");
      if (result) {
        this.startLoader();
        const apiBaseUrl = process.env.VUE_APP_API_URL;
        const payload = {
          Name: "B",
          AltCoinID: record.ID,
          Secure: "AA",
          PoloniexLendingCheck: 1,
        };
        this.$http.get(`${apiBaseUrl}/GetInfo`, {
          params: payload,
          withCredentials: true,
        }).then(response => {
          this.stopLoader();
          if (response.data.status_code === 200) {
            if (response.data.result.toLowerCase() === "success") {
              this.$toast.fire("", response.data.message, "success");
              saveNotification(response.data.message);
            }
          } else if (response.data.status_code === 400) {
            if (response.data.message.toLowerCase() === "no permission") {
              logout();
            }
          }
        }).catch(error => {
          this.stopLoader();
          console.log(error);
        });
      }
    },
    async poloniexDepositWithdrawalCheck(record, index) {
      const result = await this.validate("Poloniex deposit and withdrawal check");
      if (result) {
        this.startLoader();
        const apiBaseUrl = process.env.VUE_APP_API_URL;
        this.$http.get(`${apiBaseUrl}/GetInfo`, {
          params: {
            AltCoinID: record.ID,
            Name: "B",
            Secure: "AA",
            PoloniexDepositWithdrawalCheck: 1,
          },
          withCredentials: true,
        }).then(response => {
          this.stopLoader();
          if (response.data.status_code === 200) {
            if (response.data.result.toLowerCase() === "success") {
              this.$toast.fire("", response.data.message, "success");
              saveNotification(response.data.message);
            }
          } else if (response.data.status_code === 400) {
            if (response.data.message.toLowerCase() === "no permission") {
              logout();
            }
          }
        }).catch(error => {
          this.stopLoader();
          console.log(error);
        });
      }
    },
    async sendFiatPayoutEmail(record) {
      const apiBaseUrl = process.env.VUE_APP_API_URL;
      this.$http.get(`${apiBaseUrl}/GetInfo`, {
        params: {
          AltCoinID: record.ID,
          FIATPAYOUTLINKS: "",
        },
        withCredentials: true,
      }).then(response => {
        this.stopLoader();
        if (response.data.status_code === 200) {
          if (response.data.result.toLowerCase() === "success") {
            this.$toast.fire("", "Fait payouts email sent successfully", "success");
            saveNotification("Fait payouts email sent successfully");
          }
        } else if (response.data.status_code === 400) {
          if (response.data.message.toLowerCase() === "no permission") {
            logout();
          }
        }
      }).catch(error => {
        this.stopLoader();
        console.log(error);
      });
    },
    async ltrCheck(record, index) {
      const result = await this.validate("LTR check");
      if (result) {
        record.isLTRRefreshing = true;
        this.paginatedBalanceSheetList.splice(index, 1, record);
        const apiBaseUrl = process.env.VUE_APP_API_URL;
        this.$http.get(`${apiBaseUrl}/GetInfo`, {
          params: {
            AltCoinID: record.ID,
            Name: "B",
            Secure: "AA",
            LTRCheck: 1,
          },
          withCredentials: true,
        }).then(response => {
          record.isLTRRefreshing = false;
          this.paginatedBalanceSheetList.splice(index, 1, record);
          if (response.data.status_code === 200) {
            if (response.data.result.toLowerCase() === "success") {
              this.$toast.fire("", "LTR check performed successfully", "success");
              saveNotification("LTR check performed successfully");
            }
          } else if (response.data.status_code === 400) {
            if (response.data.message.toLowerCase() === "no permission") {
              logout();
            }
          }
        }).catch(error => {
          record.isLTRRefreshing = false;
          this.paginatedBalanceSheetList.splice(index, 1, record);
          console.log(error);
        });
      }
    },
    startLoader() {
      this.$swal.fire({
        title: "",
        text: "Please wait...",
        showConfirmButton: false,
        backdrop: true,
      });
    },
    stopLoader() {
      this.$swal.close();
    },
    reload() {
      this.getBalanceSheet();
    },
    getMerchantDetail(id) {
      const apiBaseUrl = process.env.VUE_APP_ADMIN_API_URL;
      this.$http.get(`${apiBaseUrl}/?Call=UserDetail`, {
        headers: {
          Authorization: "Bearer " + this.jwtToken,
        },
        params: {
          ID: id,
        },
      }).then(response => {
        if (response.status === 200) {
          this.supportUser = Object.assign({}, response.data.data);
        }
      }).catch(_error => {
      });
    },
  },
  beforeMount() {
    this.getMerchantDetail(1);
  },
  mounted() {
    if (Object.keys(this.$route.query).length === 3) {
      this.altCoinID = this.$route.query.id.toString();
      this.buy = this.$route.query.buy ? "1" : "0";
      this.isOnline = this.$route.query.online ? "1" : "0";
      this.$router.replace({
        ...this.$router.currentRoute,
        query: {},
      });
    }
    this.sortKey = "RealActualMultiplySell";
    this.order = "desc";
    if (this.buy === "1") {
      if (this.isOnline === "1") {
        this.balanceSheet = this.balanceSheetBuyEnabledOnlineData;
      } else {
        this.balanceSheet = this.balanceSheetBuyEnabledOfflineData;
      }
    } else if (this.buy === "0") {
      if (this.isOnline === "1") {
        this.balanceSheet = this.balanceSheetBuyDisabledOnlineData;
      } else {
        this.balanceSheet = this.balanceSheetBuyDisabledOfflineData;
      }
    } else {
      if (this.isOnline === "1") {
        this.balanceSheet = this.balanceSheetBuyBothOnlineData;
      } else {
        this.balanceSheet = this.balanceSheetBuyBothOfflineData;
      }
    }
    this.meta = Object.assign({}, this.meta, {
      total: this.balanceSheet.length,
      page: 1,
      offset: 0,
    });
  },
  beforeDestroy() {
    this.$store.commit(STOP_LOADING);
  },
};
</script>

<style scoped lang="scss">
.border-left-red {
  border-left: 2px solid #dc3545;
}

.blink {
  animation: spin-animate 1.5s ease-in infinite;
}

@keyframes spin-animate {
  from {
    color: rgb(54, 127, 169);
    transform: rotate(0deg);
    opacity: 1;
  }

  to {
    color: #b00808;
    transform: rotate(359deg);
    opacity: 0.3;
  }
}

.blinker {
  -webkit-animation: fadeIn 1.5s ease-in infinite;
}

@-webkit-keyframes fadeIn {
  40% {
    opacity: 0.4;
  }

  80% {
    opacity: 0.8;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  40% {
    opacity: 0.4;
  }

  80% {
    opacity: 0.8;
  }

  100% {
    opacity: 1;
  }
}
</style>
