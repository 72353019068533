<template>
  <div aria-labelledby="bsModalLabel" class="modal fade"
       data-keyboard="false" data-toggle="modal"
       id="bsModalPoolCheckAndConsolidate" role="dialog" tabindex="-1">
    <div @click.stop class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <div class="text-center">
            <div class="swal2-icon swal2-warning swal2-icon-show" style="display: flex;">
              <div class="swal2-icon-content">!</div>
            </div>
          </div>
          <p class="text-center fs-16">Pool check and consolidate</p>
          <div class="text-center">
            <button class="btn btn-primary btn-flat mr-8" type="button"
              @click="performAction('poolMaster')">PoolMaster</button>
            <button class="btn btn-info btn-flat mr-8" type="button"
              @click="performAction('fiatBridge')">FiatBridge</button>
            <button class="btn btn-primary btn-flat mr-8" type="button"
              @click="performAction('poolCheck')">Pool Check Only</button>
            <button class="btn btn-default btn-flat" type="button"
              @click="performAction('cancel')">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PoolCheckAndConsolidateModal",
  data() {
    return {
      record: null,
      callback: Function,
    };
  },
  methods: {
    performAction(action) {
      this.close();
      if (action !== "cancel") {
        this.callback(action);
      }
    },
    close() {
      $("#bsModalPoolCheckAndConsolidate").modal("hide");
    },
    showModal(args) {
      this.callback = args.callback;
      $("#bsModalPoolCheckAndConsolidate").modal("show");
    },
  },
  mounted() {
    this.$bus.$on("poolCheckAndConsolidateModal", this.showModal);
  },
  beforeDestroy() {
    this.$bus.$off("poolCheckAndConsolidateModal", this.showModal);
  },
};
</script>

<style scoped>
.fs-16 {
  font-size: 16px;
}
</style>
